.card {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1rem;
    width: 100%;
    height: 100%;
    margin-bottom: 2px;
    opacity: 0.7;
    color: var(--theme-color-1);
    background-color: var(--theme-bg-2);
    border-radius: 2px;
}

.card:hover {
    opacity: 1;
}

.link {
    display: flex;
}

.link:focus {
    outline: 2px solid var(--theme-highlight-1);
    outline-offset: -1px;
}

.highlight {
    border: 2px solid var(--theme-highlight-1);
}