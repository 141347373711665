.container {
  z-index: 100;
  position: fixed;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 100px);
  max-width: calc(100% - 100px);
  background: var(--theme-bg-2);
  border-radius: 2px;
  color: var(--theme-color-1);
  overflow: hidden;
}

.default {
  padding: 10px;
  min-width: 600px;
}

.title {
  font-size: 1rem;
  margin-top: 0px;
  text-transform: uppercase;
  font-weight: bold;
}

.body {
  margin: 10px 0;
}

.button {
  float: right;
  padding: 10px 20px;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  background: var(--theme-bg-3);
  color: var(--theme-color-1);
}

.button:focus,
.button:active {
  outline: 2px solid var(--theme-highlight-1);
}

@media (max-width: 800px) {
  .modal {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    min-width: auto;
    width: 100%;
    border-radius: nonw;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
}

.close {
  position: absolute;
  top: 5px;
  right: 7px;
  cursor: pointer;
  opacity: 0.4;
  background: transparent;
  border: none;
  color: white;
  transition: opacity 0.5s var(--ease-1);
}

.close:focus {
  outline: none;
  color: var(--theme-highlight-1);
}

.close:focus,
.close:hover {
  opacity: 1;
}
