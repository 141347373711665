.screen {
  padding: 80px;
  display: flex;
  gap: 80px;
}

.btn {
  border: 8px solid var(--theme-bg-3);
  transition: 0.2s ease;
  width: 300px;
  height: 400px;
  border-radius: 10px;
  background-color: var(--theme-bg-2);
  color: var(--theme-color-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 60px 20px;
  border: 8px solid var(--theme-bg-3);
}

.icon {
  flex-grow: 1;
  color: var(--theme-bg-3);
  /* color: rgba(var(--theme-bg-1-rgb), 0.6); */
}

.btn > span {
  text-transform: uppercase;
  font-size: 0.9em;
}

.btn:active,
.btn:focus {
  outline: none;
  border: 8px solid var(--theme-highlight-1);
}

/* TOOD: Mobile styles */
