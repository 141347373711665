.title {
  color: var(--theme-color-1);
  text-transform: uppercase;
  font-size: 1rem;
}

.edit-container {
  display: inline-block;
  color: var(--theme-color-1);
}

.edit-icon,
.delete-icon {
  opacity: 0.7;
  cursor: pointer;
  margin-left: 10px;
}

button:hover > .edit-icon,
button:focus > .edit-icon,
button:hover > .delete-icon,
button:focus > .delete-icon {
  opacity: 1;
}

.edit-container button:focus > .delete-icon,
.delete-icon:hover {
  color: var(--theme-warning-1);
}

.add-container {
  color: var(--theme-highlight-1);
  display: grid;
  place-items: center;
  padding: 30px;
  border: 1px dashed var(--theme-highlight-1);
  background-color: transparent;
  transition: background-color 0.5s var(--ease-1);
}

.add-container:hover,
.add-container:focus {
  outline: none;
  border-style: solid;
  background-color: rgba(var(--theme-highlight-1-rgb), 0.3);
}
