@media (min-width: 600px) {
    .categories {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 800px) {
    .categories {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (max-width: 600px) {
    .categories {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}