.selectContainer {
  margin-bottom: 10px;
}

/* .selectContainer:last-of-type {
    margin-bottom: 0;
} */

.select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 7px;
  background: var(--theme-bg-1);
  border: none;
  color: var(--theme-color-1);
  outline: none;
  font-size: 1rem;
}

.select:active,
.select:focus {
  outline: 1px solid var(--theme-highlight-1);
}

.label {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
